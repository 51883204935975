.btnMain {
    height: 34px;
    width: 233px;
    font-size: 15px;
}
.btnNav {
    height: 34px;
    width: 2313px;
    font-size: 15px;
}

.btnActual {    
    font-size: 15px;
}

.textFieldMain {
    height: 34px;
    width: 233px;
    font-size: 15px;
}
.textFieldSearch {
    height: 34px;
    width: 233px;
    font-size: 10px;
}
.textFieldMessage {   
    width: 377px;
    font-size: 15px;
}

.labelTitlePrimary{
    height: 34px;
    width: 233px;
    font-size: 21px;
    text-align: start;
    font-weight: bold;
    color: #0288d1;
}
.labelTitlePrimarySmall{
    height: 34px;
    width: 233px;
    font-size: 15px;
    text-align: start;
    font-weight: bold;
    color: #0288d1;
}
.labelTitleSecondary{    
    font-size: 21px;
    text-align: start;
    font-weight: bold;
    color: #000000;
}
.labelActual{    
    font-size: 15px;
    text-align: start;    
    color: #0288d1;
}
.labelActualSmall{    
    font-size: 13px;
    text-align: start;    
    color: #858585;
    padding-left: 5px;
}
.labelProfile{    
    font-size: 15px;
    text-align: start;    
    color: #858585;
    padding-left: 5px;
}
.labelNewsMessage{    
    font-size: 15px;
    text-align: start;    
    color: #000000;
    padding-left: 5px;
}
.labelNewsUser{    
    font-size: 15px;
    text-align: start;  
    font-weight: bold;  
    color: #000000;
    padding-left: 5px;
}
.labelNewsOther{    
    font-size: 15px;
    text-align: start;  
    font-weight: bold;  
    color: #858585;
    padding-left: 5px;
}
.labelProfileSecondary{    
    font-size: 15px;
    text-align: start; 
    color: #FFFFFF;
    padding-left: 5px;
}
.labelProfilePrimary{    
    font-size: 21px;
    text-align: start; 
    color: #FFFFFF;
    padding-left: 5px;
}